import { NoticiasApi } from "../Api/NoticiasApi";
import { LatestNews } from "../ui/views/LatestNews";
import { NoticiasView } from "../ui/views/NoticiasView";
import { Pagination } from "../util/Pagination";

export class NoticiasController {
    constructor() {
        this._noticiasApi = new NoticiasApi();
        this._noticiasView = new NoticiasView(".news-box__wrapper");
        this._latestNewsView = new LatestNews(".news-card__group");
        this._pagination = new Pagination();
    }

    async getAllNoticias() {
        const noticias = await this._noticiasApi.getAllNoticias();
        
        this._pagination = new Pagination(
            ".news-indexes",
            noticias,
            1,
            4,
            (e) => this._noticiasView.update(e)
        );

        this._pagination.addPagination();
    }

    async getByCategory(categoryId) {
        const noticias = await this._noticiasApi.getByCategory(categoryId);

        this._pagination = new Pagination(
            ".news-indexes",
            noticias,
            1,
            4,
            (e) => this._noticiasView.update(e)
        );

        this._pagination.addPagination();
    }

    async getLatestNews() {
        const noticias = await this._noticiasApi.getAllNoticias();

        this._latestNewsView.update(noticias.slice(0, 3));
    }


}